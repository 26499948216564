import React from "react";
import { Link } from "gatsby";

import Layout from "components/layout";
import SEO from "components/seo";

function NotFoundPage() {
    return (
        <Layout>
            <SEO
                pageData={{
                    title: `404: Not found`,
                    description: `Page cannot be found.`
                }}
            />
            <div className="text-center">
                <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
                    Looks like that page doesn't exist. Try finding your way from <Link to="/" className="underline">home</Link>
                </h2>
            </div>
        </Layout>
    );
}

export default NotFoundPage;
